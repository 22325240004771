.grecaptcha-badge {
    visibility: hidden;
}

body.recaptcha {
    .grecaptcha-badge {
        visibility: visible;
        margin-bottom: 50px;
    }
}

.left-section-btn {
    text-align: left;
    margin-bottom: 13px;
}

.add-button-container {
    margin-bottom: 13px;
}
