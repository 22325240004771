@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham.css';
@import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

@import 'ag-grid-community/src/styles/ag-grid.scss';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin';
@import 'ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin';
@import 'ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin';

/*
    AG Table styles start
*/

.table-wrapper-full-page-height ag-grid-angular {
    height: calc(100vh - 118px);
}

.table-wrapper-full-page-height-with-tabs ag-grid-angular {
    height: calc(100vh - 200px);
}

.table-wrapper-without-filters ag-grid-angular {
    height: calc(100vh - 75px);
}

.table-wrapper-height-in-detail-renderer ag-grid-angular{
    height: 520px;
}

.ag-with-total ag-grid-angular {
    height: calc(100vh - 221px);
}


@media screen and (min-width: 1092px) {
    .ag-with-total ag-grid-angular {
        height: calc(100vh - 215px);
    }
}

.ag-total .ag-cell-value {
    font-weight: bold;
}

.ag-cell-value {
    padding: 0 !important;
}

.ag-row.detail-row-max-height {
    .ag-details-row.ag-details-row-auto-height {
        max-height: 370px;

        .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
            padding-top: 45px;
        }

        .ag-root.ag-layout-auto-height {
            max-height: 300px;

            .ag-body-viewport.ag-layout-auto-height {
                overflow-y: auto;
            }
        }
    }
}

.ag-row.detail-row-max-height-for-renderer {

    &.inner-detail-table {
        #ag-table-wrapper {
            max-height: 340px;

            ag-grid-angular {
                .ag-body-viewport.ag-layout-auto-height {
                    max-height: 210px;
                }
            }
        }
    }

    &.ag-row-hover {
        background-color: inherit;
    }

    .ag-row-group {
        padding: 0 !important;
    }

    #ag-table-wrapper {
        max-height: 530px;

        ag-grid-angular {
            height: 100%;

            .ag-body-viewport.ag-layout-auto-height {
                max-height: 410px;
                overflow-y: auto;
            }
        }
    }
}

.two-line-cell-renderer {
    font-size: 0.928rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: min(20px);
}

.two-line-cell-renderer-top {
    height: 18px;
}

.two-line-cell-renderer-bottom {
    height: 18px;
}

.auto-height-wrapper {
    display: block;
    
    ag-grid-angular {
        height: auto;
    }
}

.with-borders {

    .ag-theme-alpine {
        .ag-ltr .ag-row .ag-cell:not(:first-child) {
            border-left-color: #dde2eb;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #dde2eb;
        }
    }

    .ag-theme-alpine-dark {
        .ag-ltr .ag-row .ag-cell:not(:first-child) {
            border-left-color: #304562;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border-left-color: #304562;
        }
    }

}

.without-header {
    .ag-header {
        display: none;
    }

    .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
        padding-top: 0 !important;
    }
}


.ag-theme-alpine {
    .ag-ltr .ag-cell.ag-cell-bordered {
        border-left-color: #dde2eb;
        border-right-color: #dde2eb;
        padding: 0 !important;

        .two-line-cell-renderer-align {
            border-bottom: 1px solid #dde2eb;
        }
    }
}

.ag-theme-alpine-dark {
    .ag-ltr .ag-cell.ag-cell-bordered {
        border-left-color: #304562;
        border-right-color: #304562;
        padding: 0 !important;

        .two-line-cell-renderer-align {
            border-bottom: 1px solid #304562;
        }
    }

    .ag-row .ag-cell-value .ag-row-group-leaf-indent {
        margin: 0;
    }
}

.ag-theme-alpine-dark {
    --ag-selected-row-background-color: #304562;
    --ag-row-hover-color: #304562;

    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        font-family: $fontFamily,
        background-color: $topbarBg,
        odd-row-background-color: #17212F,
        header-background-color: $bodyBg,
        border-color: $dividerColor,
        secondary-border-color: $dividerColor,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg,
        range-selection-border-color: #629aeb33,
        header-height: 40px
    ));

    input[class^=ag-][type=text] {
        border-color: #304562;
        background: #17212f;
    }

    .ag-floating-filter-button-button {
        color: #89929F;
    }

    .ag-header-cell-text,
    .ag-row {
        color: $tableTextColor;
    }

    .ag-row-group-expanded {
        background: #304562;
    }

    .ag-row-focus {
        background: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
    }

    .ag-row-hover.ag-row-selected::before {
        background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
        background-image: none;
    }

    .ag-icon-filter {
        cursor: pointer;
    }
}

.view-notes-dialog .ag-theme-alpine-dark {
    @include ag-theme-alpine-dark((
        // use theme parameters where possible
        background-color: $bodyBg,
        odd-row-background-color: $bodyBg,
        header-background-color: $bodyBg,
        border-color: $bodyBg,
        secondary-border-color: $bodyBg,
        control-panel-background-color: $bodyBg,
        subheader-background-color: $bodyBg
    ));

    .ag-row-hover {
        background: transparent;
    }

    .ag-group-expanded {
        background-color: $bodyBg;
    }
}

.ag-theme-alpine {
    --ag-selected-row-background-color: #EFF8FF;
    --ag-row-hover-color: #EFF8FF;

    @include ag-theme-alpine((
        font-family: $fontFamily,
        background-color: #FFFFFF,
        odd-row-background-color: #FCFCFD,
        header-background-color: #FCFCFD,
        border-color: #E4E5F1,
        range-selection-border-color: #e4e4e4,
        header-height: 40px
    ));

    .ag-header-cell-text,
    .ag-row {
        color: #343A40;
    }

    .ag-group-contracted,
    .ag-group-expanded {
        color: #CBCBD8;
    }

    .ag-row-group-expanded {
        background: #EFF8FF;
    }

    .ag-row-focus {
        background: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
    }

    .ag-row-hover.ag-row-selected::before {
        background-color: var(--ag-row-hover-color, rgba(33, 150, 243, 0.1));
        background-image: none;
    }

    .ag-icon-filter {
        cursor: pointer;
    }

    .ag-cell-value .ag-row-group-leaf-indent {
        margin: 0;
    }

    .ag-menu-option {
        color: #54545F;

        .ag-menu-option-icon, .ag-menu-option-popup-pointer {
            color: #B3B3BC;
        }
    }
}

.ag-header-cell {
    font-weight: 600;
    font-size: 0.928rem;
    padding-left: 6px !important;
    padding-right: 6px !important;

    &.select-all {
        .ag-header-cell-comp-wrapper {
            overflow: visible;
        }
    }
}

.ag-cell {
    font-weight: 400;
    font-size: 0.928rem;
    padding-left: 4px !important;
    padding-right: 4px !important;

    &.ag-cell-inline-editing {
        padding: 0 !important;
    }

    > span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        .icon {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 8px;

            img {
                height: 20px;
            }
        }
    }
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
}

$container-selectors: "\
    .ag-layout-auto-height .ag-center-cols-clipper, \
    .ag-layout-auto-height .ag-center-cols-container, \
    .ag-layout-print .ag-center-cols-clipper, \
    .ag-layout-print .ag-center-cols-container";

#{$container-selectors} {
    min-height: 80px !important;
}

.no-min-height #{$container-selectors} {
    min-height: unset !important;
}

.radiused .ag-root-wrapper {
    border-radius: 5px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.row-data-loading {
    i {
        margin-right: 10px;
    }
}

.ag-root-wrapper .ag-body-viewport .ag-details-row {
    padding: 12px;
}

.ag-menu {
    box-shadow: $mainShadow !important;
}

.view-notes-table .ag-root-wrapper {
    height: 320px;
}

.view-notes-inner-table .ag-root-wrapper {
    border-radius: initial;
    height: 100%;
}

.view-notes-inner-table .ag-center-cols-viewport {
    overflow-x: hidden;
}

/*
    AG Table styles end
*/
